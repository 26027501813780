import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import CustomNavbar from './Components/Element/CustomNavbar';
import CustomFooter from './Components/Element/CustomFooter';
//import PricingCard from './Components/Page/LandingPage/Pricing';
import Acceuil from './Components/Page/LandingPage/Acceuil';
import Fonctionnalite from './Components/Page/LandingPage/Fonctionnalite';
import PaymentMethod from './Components/Page/Payment/PaymentMethod';
import ConfirmPlan from './Components/Page/Payment/ConfirmPlan';
import PaymentForm from './Components/Page/Payment/PaymentForm';
import AfficheInfo from './Components/Page/Payment/AfficheInfo';
import WaitPage from './Components/Page/Payment/WaitPage';
import ProfileForm from './Components/Page/ProfileForm/Profile';
import ThankYouPage from './Components/Page/ThankYou/ThankYouPage';
import VirtualBusinessCard from './Components/Page/VirtualBusinessCard/VirtualBusinessCard';
import ContactForm from './Components/Page/Payment/ContactForm ';
import NotFoundPage from './Components/Page/LandingPage/NotFoundPage';
import WelcomePage from './Components/Page/Payment/WelcomePage';
import Profile from './Components/Page/auth/Profil';
import ProfileEdit from './Components/Page/ProfileEdit/ProfileEdit';
import Home from './Components/Page/Home/Home';
import ProtectedRoute from './utils/ProtectedRoute';
import Login from './Components/Page/auth/Login';
import Register from './Components/Page/auth/Register';
// Layout component with CustomNavbar and CustomFooter
const Layout = ({ children }) => (
  <>
    <CustomNavbar />
    {children}
    <CustomFooter />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

function App() {
  return (
    <Router>
      <Routes>
        {/* Routes with the base layout (CustomNavbar, CustomFooter) */}
        <Route path="/" element={<Layout><Acceuil /></Layout>} />      
        <Route path="/Fonctionnalites" element={<Layout><Fonctionnalite /></Layout>} />
        <Route path="/register" element={<Register />} />
        <Route path="/payment" element={<PaymentMethod />} />
        <Route path="/ConfirmPlan" element={<ConfirmPlan />} />
        <Route path="/PaymentForm" element={<PaymentForm />} />
        <Route path="/info" element={<AfficheInfo />} />
        <Route path="/WaitPage" element={<WaitPage />} />
        
       
        <Route path="/Login" element={<Login />} />
        <Route path="/Register" element={<Register />} />
        {/* Route for VirtualBusinessCard without CustomNavbar and CustomFooter */}
       <Route path="/virtualBusinessCard/:userId/:profileId" element={<VirtualBusinessCard />} /> {/* new version to avoid searching the entire database */}
         <Route path="/virtualBusinessCard/:profileId" element={<VirtualBusinessCard />} /> {/* old version */}
        <Route path="/ContactForm" element={<ContactForm />} />
        <Route path="/welcome" element={<WelcomePage />} />


      {/* Protected Routes */}  
        <Route element={<ProtectedRoute />}>
                <Route path="/payment" element={<PaymentMethod />} />
                <Route path="/ConfirmPlan" element={<ConfirmPlan />} />
                <Route path="/PaymentForm" element={<PaymentForm />} />
                <Route path="/WaitPage" element={<WaitPage />} />
                <Route path="/profileform" element={<ProfileForm />} />
                <Route path="/thankyou/:profileId" element={<ThankYouPage />} />
                <Route path="/profileform/:orderId" element={<ProfileForm />} />
                <Route path="/thankyou/:orderId/:profileId" element={<ThankYouPage />} />
                <Route path="/profileEdit/:orderId/:profileId" element={<ProfileEdit />} />
                {/* Routes without the base layout */}
                <Route path="/profile" element={<Profile />} />
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/espaceClient" element={<Home />} />
          </Route>
      </Routes>
    </Router>
  );
}

export default App;
