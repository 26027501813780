import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useProfileData from '../../../hooks/useProfileData';
import card from "../../../images/elitecard-bg.png";

const GenericCard = ({ profileId, userId, width = 400, height = 220, profileData: initialProfileData, onProfileDataChange }) => {
  // Utilisation des données de profil passées en prop ou récupération des données via le hook
  const { profileData, loading } = useProfileData(userId, profileId);

  // Si `initialProfileData` est fourni, il prend le pas sur les données récupérées
  const dataToUse = initialProfileData || profileData;

  const textSizeClass = width >= 300 ? 'text-lg' : 'text-sm font-semibold';
  const textSizeClass2 = width >= 300 ? 'text-base' : 'text-xs font-semibold';
  const divSizeClass = width === 400 ? 'w-full' : 'w-2/3';

  useEffect(() => {
    // Appel de la fonction `onProfileDataChange` si elle est définie
    if (onProfileDataChange && profileData) {
      onProfileDataChange(profileData);
    }
  }, [profileData, onProfileDataChange]);

  // Afficher l'icône de chargement si les données sont en cours de chargement
  if (loading && !initialProfileData) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="w-10 h-10 border-4 border-white border-dashed rounded-full animate-spin"></div>
      </div>
    );
  }

  // Afficher le message "Profile data not found" si les données ne sont pas trouvées
  if (!dataToUse) {
    return <div className="flex justify-center items-center h-full text-red-500">Profile data not found</div>;
  }

  return (
    <div 
      className="bg-white rounded-lg p-2 relative"
      style={{ 
        width: `${width}px`, 
        height: `${height}px`, 
        backgroundImage: `url(${card})`, 
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
      }}
    >
      <h3 className={`absolute top-4 left-4 text-gray-800 font-bold font-serif ${textSizeClass} ${divSizeClass} `}>
        {dataToUse.fullName || "Nom Complet"}
      </h3>
      <p className={`absolute bottom-4 left-4 text-gray-500 italic font-bold ${textSizeClass2} ${divSizeClass} `}>
        {dataToUse.company}
      </p>
    </div>
  );
};

GenericCard.propTypes = {
  profileId: PropTypes.string,
  userId: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  profileData: PropTypes.object,
  onProfileDataChange: PropTypes.func,
};

export default GenericCard;
