import React from 'react'
import { SketchPicker } from 'react-color';
import { useState, useRef } from 'react';
import { useEffect } from 'react';
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";


export default function ThemeEdit( { profileData, onUpdate } ) {
    
    const [custombackgroundcolor, setCustombackgroundcolor] = useState(profileData?.background || '#a855f7');
    const [custombodycolor, setCustombodycolor] = useState(profileData?.bodycolor || '#ffffff');
    const [fromcolor, setFromcolor] = useState(profileData?.fromcolor || '#3b82f6');
    const [tocolor, setTocolor] = useState(profileData?.tocolor || '#a855f7');
    const [iconcolor, setIconcolor] = useState(profileData?.icon || '#a855f7');
    const [icontitlecolor, setIcontitlecolor] = useState(profileData?.fieldstitle || '#8b5cf6');
    const [iconvaluecolor, setIconvaluecolor] = useState(profileData?.fielsvalue || '#4b5563');
    const [informationcolor, setInformationcolor] = useState(profileData?.fielsvalue || '#4b5563');
    const [profileshape, setProfileshape] = useState(profileData?.profileshape || 'circle');
    const [logoshape, setLogoshape] = useState(profileData?.logoshape || 'circle');
    const [appearance, setAppearance] = useState({
        left: false,
        right: false,
        background: false,
        body: false,
        icons: false,
        iconstitle: false,
        iconsvalue: false,
        information: false,
        pictureshapes: true,
      });
    useEffect(() => {
        onUpdate({    colorScheme: {
            fromcolor: fromcolor,
            tocolor: tocolor,
            bodycolor: custombodycolor,
            background: custombackgroundcolor,
            icon: iconcolor,
            fieldstitle: icontitlecolor,
            fieldsvalue: iconvaluecolor,
            informationcolor: informationcolor,
            profileshape: profileshape,
            logoshape: logoshape
          }});
      }, [custombackgroundcolor, custombodycolor, fromcolor, tocolor, iconcolor, icontitlecolor, iconvaluecolor, informationcolor,profileshape,logoshape]);
      const handleprofileshape = (shape) => {
        if (shape == 'rectangle')
            setProfileshape('rectangle');
        else
            setProfileshape('circle');
        
      }
      const handlelogoshape = (shape) => {
        if (shape == 'rectangle')
            setLogoshape('rectangle');
        else
            setLogoshape('circle');
      }
      const toggleAppearance = (component) => {
        setAppearance(prevState => ({
          ...prevState,
          [component]: !prevState[component]
        }));
      };
    return (
        <div className="w-full  p-6 bg-white">
            {/* mobile version */}

            <div className="flex  w-full md:hidden flex-col space-y-5 ">
            <div className="w-full grid grid-cols-2 "  >
                    <div className="flex flex-col w-fit  " >
                        <div className=" flex justify-start  space-x-3 flex-row w-full  ml-5 items-center  mb-1 ">
                            <h1 style={{textAlign: 'center' }} className="w-fit">Couleur gauche</h1>
                            <h1 className={`border rounded p-3 pt-1 pb-1 border-gray-500 `} style={{textAlign: 'center' ,justifycontent:'center' , color: `${fromcolor == "#ffffff" ? "#000000" : fromcolor}`}}>{fromcolor}</h1>
                            <button
                            className="bg-violet-500"
                                style={{ zIndex: 10, padding: '5px',  borderRadius: '5px', color: 'white'}}
                                onClick={()=>toggleAppearance('left')}
                            >
                            {appearance.left ? 'couleur' : 'aperçu'} 
                            </button>
                        </div>
                        <div
                        className='justify-center items-center'
                            style={{display: (appearance.left ? 'none' : 'flex'), width: '100%', justifyContent: 'center', alignItems: 'center'}}
                        >

                        <SketchPicker
                            width={'full'}
                            color={fromcolor}
                            onChangeComplete={(color) => setFromcolor(color.hex)}
                        />
                        </div>
                    <div style={{justifyContent: 'center', alignItems: 'center', display: (appearance.left ? 'flex' : 'none') , width: 'full'}}>
                        <img src="/images/fromcolorarrow.png" alt="" style={{width: "255px", height: "255px" }} />
                    </div>
                    </div>
            </div>
            <div className="fw-full grid grid-cols-2">
                <div className="flex flex-col w-fit" >
                <div className=" flex justify-start space-x-3 w-full flex-row  ml-5 items-center  mb-1">

                    <h1 style={{textAlign: 'center' }}>Couleur droite</h1>
                    <h1 className={`border rounded p-3 pt-1 pb-1 border-gray-500 `} style={{textAlign: 'center' ,justifycontent:'center' , color: `${tocolor == "#ffffff" ? "#000000" : tocolor}`}}>{tocolor}</h1>
                    <button
                    className="bg-violet-500"
                        style={{ zIndex: 10, padding: '5px',  borderRadius: '5px', color: 'white'}}
                        onClick={()=>toggleAppearance('right')}
                    > 
                    {appearance.right ? 'couleur' : 'aperçu'}
                    </button>

                </div>
                <div className=""
                    style={{display: (appearance.right ? 'none' : 'flex'), width: '100%', justifyContent: 'center', alignItems: 'center'}}
                >

                    <SketchPicker
                        width={250}
                        color={tocolor}
                        onChangeComplete={(color) => setTocolor(color.hex)}
                        />
                        </div>
                    <div style={{justifyContent: 'center', alignItems: 'center', display: (appearance.right ? 'flex' : 'none') , width: 'full'}}>
                        <img src="/images/tocolorarrow.png" alt="" style={{width: "255px", height: "255px" }} />
                    </div>
                    </div>
            </div>
                <div className="fw-full grid grid-cols-2">
                <div className="flex flex-col w-fit" >
                <div className=" flex justify-start  w-full space-x-3 flex-row  ml-5 items-center  mb-1">
                        <h1 style={{textAlign: 'center' }}>Couleur supérieure</h1>
                        <h1 className={`border rounded p-3 pt-1 pb-1 border-gray-500 `} style={{textAlign: 'center' ,justifycontent:'center' , color: `${custombackgroundcolor == "#ffffff" ? "#000000" : custombackgroundcolor}`}}>{custombackgroundcolor}</h1>
                        <button
                        className="bg-violet-500"
                            style={{ zIndex: 10, padding: '5px',  borderRadius: '5px', color: 'white'}}
                            onClick={()=>toggleAppearance('background')}
                            > 
                            {appearance.background ? 'couleur' : 'aperçu'}
                        </button>
                    </div>
                    <div className=""
style={{display: (appearance.background ? 'none' : 'flex'), width: '100%'}}
                    >
                    <SketchPicker
                        width={250}
                        
                        color={custombackgroundcolor}
                        onChangeComplete={(color) => setCustombackgroundcolor(color.hex)}
                        />
                        </div>
                    <div style={{justifyContent: 'center', alignItems: 'center', display: (appearance.background ? 'flex' : 'none') , width: 'full'}}> 
                    <img src="/images/arrowbackground.png" alt="" style={{width: "255px", height: "255px" }} />
                    </div>
                </div>
                </div>
                
                <div className="fw-full grid grid-cols-2">
                <div className="flex flex-col w-fit" >
                <div className=" flex justify-start  space-x-3 flex-row  ml-5 items-center w-full  mb-1">
                            <h1 style={{textAlign: 'center' }} className='w-[100%] '>Couleur du corps</h1>
                            <h1 className={`border rounded pt-1 pb-1 border-gray-500 `} style={{textAlign: 'center' ,justifycontent:'center' , color: `${custombodycolor == "#ffffff" ? "#000000" : custombodycolor}`}}>{custombodycolor}</h1>
                            <button
                            className="bg-violet-500"
                            style={{ zIndex: 10, padding: '5px',  borderRadius: '5px', color: 'white'}}
                            onClick={()=>toggleAppearance('body')}
                            > 
                        {appearance.body ? 'couleur' : 'aperçu'}
                            </button>
                        </div>
                        <div className=""
style={{display: (appearance.body ? 'none' : 'flex'), width: '100%'}}
                        >

                    <SketchPicker
                        width={250}
                        
                        color={custombodycolor}
                        onChangeComplete={(color) => setCustombodycolor(color.hex)}
                        />
                        </div>
<div style={{justifyContent: 'center', alignItems: 'center', display: (appearance.body ? 'flex' : 'none') , width: 'full'}}>
                    <img src="/images/arrowbody.png" alt="" style={{width: "255px", height: "255px" }} />
                        </div>
                </div>
                </div>
                <div className="fw-full grid grid-cols-2">
                <div className="flex flex-col w-fit" >
                <div className=" flex justify-start  w-full space-x-3 flex-row  ml-5 items-center  mb-1">
                            <h1 style={{textAlign: 'center' }}>Couleur de l'icône</h1>
                            <h1 className={`border rounded p-3 pt-1 pb-1 border-gray-500 `} style={{textAlign: 'center' ,justifycontent:'center' , color: `${iconcolor == "#ffffff" ? "#000000" : iconcolor}`}}>{iconcolor}</h1>
                            <button
                            className="bg-violet-500"
                            style={{ zIndex: 10, padding: '5px',  borderRadius: '5px', color: 'white'}}
                            onClick={()=>toggleAppearance('icons')}
                            > 
                        {appearance.icons ? 'couleur' : 'aperçu'}
                            </button>
                        </div>
                        <div className=""
style={{display: (appearance.icons ? 'none' : 'flex'), width: '100%'}}
                        >

                    <SketchPicker 
                        color={iconcolor}
                        onChangeComplete={(color) => setIconcolor(color.hex)}
                        width={250}
                        />
                        </div>
            <div style={{justifyContent: 'center', alignItems: 'center', display: (appearance.icons ? 'flex' : 'none') , width: 'full'}}>
                        <div className="grid grid-cols-2 gap-9">
                            <img src="/images/mailcolor.png" alt="" style={{width: "75px", height: "80%" }} />
                            <img src="/images/locationcolor.png" alt="" style={{width: "75px", height: "80%" }} />
                            <img src="/images/phonecolor.png" alt="" style={{width: "75px", height: "80%" }} />
                            <img src="/images/whatsappcolor.png" alt="" style={{width: "75px", height: "80%" }} />
                        </div>
                        </div>
                </div>
                </div>
                <div className="fw-full grid grid-cols-2">
                <div className="flex flex-col w-fit" >
                <div className=" flex justify-start w-full space-x-3 flex-row  ml-5 items-center  mb-1">
                            <h1 style={{textAlign: 'center' }}> titre des icônes </h1>
                            <h1 className={`border rounded p-3 pt-1 pb-1 border-gray-500 `} style={{textAlign: 'center' ,justifycontent:'center' , color: `${icontitlecolor == "#ffffff" ? "#000000" : icontitlecolor}`}}>{icontitlecolor}</h1>
                            <button
                            className="bg-violet-500"
                            style={{ zIndex: 10, padding: '5px',  borderRadius: '5px', color: 'white'}}
                            onClick={()=>toggleAppearance('iconstitle')}
                            > 
                        {appearance.iconstitle ? 'couleur' : 'aperçu'}
                            </button>
                        </div>
                        <div className=""
style={{display: (appearance.iconstitle ? 'none' : 'flex'), width: '100%'}}
                        >

                    <SketchPicker 
                        color={icontitlecolor}
                        onChangeComplete={(color) => setIcontitlecolor(color.hex)}
                        width={250}
                        />
                        </div>
<div style={{justifyContent: 'center', alignItems: 'center', display: (appearance.iconstitle ? 'flex' : 'none') , width: 'full'}}>
                    <img src="/images/fieldstitle.png" alt="" style={{width: "255px", height: "255px"  , alignSelf: 'center'}} />
                        </div>
                </div>
                </div>
                <div className="fw-full grid grid-cols-2">
                <div className="flex flex-col w-fit" >
                <div className=" flex justify-start w-full space-x-3 flex-row  ml-5 items-center  mb-1">
                            <h1 style={{textAlign: 'center' }}> valeur des icônes </h1>
                            <h1 className={`border rounded p-3 pt-1 pb-1 border-gray-500 `} style={{textAlign: 'center' ,justifycontent:'center' , color: `${iconvaluecolor == "#ffffff" ? "#000000" : iconvaluecolor}`}}>{iconvaluecolor}</h1>
                            <button
                            className="bg-violet-500"
                            style={{ zIndex: 10, padding: '5px',  borderRadius: '5px', color: 'white'}}
                            onClick={()=>toggleAppearance('iconsvalue')}
                            > 
                        {appearance.iconsvalue ? 'couleur' : 'aperçu'}
                            </button>
                        </div>
                        <div className=""
style={{display: (appearance.iconsvalue ? 'none' : 'flex'), width: '100%'}}
                        >

                    <SketchPicker 
                        color={iconvaluecolor}
                        onChangeComplete={(color) => setIconvaluecolor(color.hex)}
                        width={250}
                        />
                        </div>
<div style={{justifyContent: 'center', alignItems: 'center', display: (appearance.iconsvalue ? 'flex' : 'none') , width: 'full'}}>
                    <img src="/images/fieldsvalue.png" alt="" style={{width: "300px", height: "80%"}} />
                        </div>
                </div>
                </div>
                <div className="w-full grid grid-cols-2">
                <div className="flex flex-col w-fit" >
                <div className=" flex justify-start w-full space-x-3 flex-row  ml-5 items-center  mb-1">
                    <h1 style={{textAlign: 'center' }}> information </h1>
                    <h1 className={`border rounded p-3 pt-1 pb-1 border-gray-500 `} style={{textAlign: 'center' ,justifycontent:'center' , color: `${informationcolor == "#ffffff" ? "#000000" : informationcolor}`}}>{informationcolor}</h1>
                    <button
                    className="bg-violet-500"
                            style={{ zIndex: 10, padding: '5px',  borderRadius: '5px', color: 'white'}}
                            onClick={()=>toggleAppearance('information')}
                            > 
                        {appearance.information ? 'couleur' : 'aperçu'}
                            </button>
                        </div>
                        <div className=""
style={{display: (appearance.information ? 'none' : 'flex'), width: '100%'}}
                        >

                    <SketchPicker 
                        color={informationcolor}
                        onChangeComplete={(color) => setInformationcolor(color.hex)}
                        width={250}
                        />
                        </div>
<div style={{justifyContent: 'center', alignItems: 'center', display: (appearance.information ? 'flex' : 'none') , width: 'full'}}>
                    <img src="/images/informationscolor.png" alt="" style={{width: "255px", height: "255px" }} />
                        </div>
                </div>
                </div>

                <div className="w-full grid grid-cols-2 "  >
                    <div className="flex flex-col pt-4 w-[200px]" style={{display: (appearance.pictureshapes ? 'flex' : 'none')}}>
                        <div className="flex flex-col justify-center items-center">

                            <h1 className='pb-4'> Affichage du profile  </h1>
                            <div className="w-full flex flex-row justify-around pl-4  ">
                                <div className="flex flex-col ">
                                    <input type="checkbox" checked={profileshape == 'rectangle'} onChange={()=>handleprofileshape('rectangle')}/>
                                    <img src="/images/rectangleimage.png" alt=""  style={{width: '75px', height: '75px'}} />
                                </div>
                                <div className="flex flex-col ">
                                    <input type="checkbox"  checked={profileshape == 'circle'} onChange={()=>handleprofileshape('circle')}/>
                                    <img src="/images/roundedimage.png" alt=""  style={{width: '75px', height: '75px'}} />
                                </div>
                            </div>

                        </div>
                        </div>

                    <div className={`flex flex-col w-[200px]`} style={{display: (appearance.pictureshapes ? 'none' : 'flex')}}>
                    <div className="flex flex-col pt-4" >
                        <div className="flex flex-col justify-center items-center">
                            <h1 className='pb-4'> Affichage du logo </h1>
                            <div className="w-full flex flex-row justify-around pl-4 ">
                                <div className="flex flex-col ">
                                    <input type="checkbox" checked={logoshape == 'rectangle'} onChange={() => handlelogoshape('rectangle')}/>
                                    <img src="/images/rectangleimage.png" alt=""  style={{width: '75px', height: '75px'}} />
                                </div>
                                <div className="flex flex-col ">
                                    <input type="checkbox" checked={logoshape == 'circle'} onChange={() => handlelogoshape('circle')}/>
                                    <img src="/images/roundedimage.png" alt=""  style={{width: '75px', height: '75px'}} />
                                </div>
                            </div>

                    </div>
                    </div>
                    </div>
                    {/* <div className="w-full h-full" style=}}> */}
                        <button className="flex justify-center rounded-full items-center bg-violet-500 text-white hover:bg-violet-600 " style={{ width: '32px' , height: '32px', justifySelf: 'end', alignSelf: 'center'}} onClick={()=>toggleAppearance('pictureshapes')}>
                            <FaArrowRight className='rounded-full  bg-violet-500 text-white hover:bg-violet-600  ' style={{display: 'flex', justifySelf: 'center', alignSelf: 'center'}} />
                        </button>
                </div>
            </div>
            {/* screen verison */}
            <div className="hidden md:flex flex-col space-y-5">
            <div className="w-full grid grid-cols-2"  >
                    <div className="flex flex-col " >
                        <div className=" flex justify-start space-x-3 flex-row  ml-5 items-center  mb-1">
                            <h1 style={{textAlign: 'center' }}>Couleur gauche</h1>
                            <h1 className={`border rounded p-3 pt-1 pb-1 border-gray-500 `} style={{textAlign: 'center' ,justifycontent:'center' , color: `${fromcolor == "#ffffff" ? "#000000" : fromcolor}`}}>{fromcolor}</h1>

                        </div>
                        <SketchPicker
                        width={250}
                            style={{display: (appearance ? 'none' : 'null')}}
                            color={fromcolor}
                            onChangeComplete={(color) => setFromcolor(color.hex)}
                        />
                    </div>
                    <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        <img src="/images/fromcolorarrow.png" alt="" style={{width: "255px", height: "255px", }} />
                    </div>
            </div>
            <div className="fw-full grid grid-cols-2">
                <div className="flex flex-col " >
                <div className=" flex justify-start space-x-3 flex-row  ml-5 items-center  mb-1">

                            <h1 style={{textAlign: 'center' }}>Couleur droite</h1>
                            <h1 className={`border rounded p-3 pt-1 pb-1 border-gray-500 `} style={{textAlign: 'center' ,justifycontent:'center' , color: `${tocolor == "#ffffff" ? "#000000" : tocolor}`}}>{tocolor}</h1>
                    </div>
                    <SketchPicker
                        width={250}
                        color={tocolor}
                        onChangeComplete={(color) => setTocolor(color.hex)}
                        />
                    </div>
                    <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', }}>
                        <img src="/images/tocolorarrow.png" alt="" style={{width: "255px", height: "255px" }} />
                    </div>
            </div>
                <div className="fw-full grid grid-cols-2">
                <div className="flex flex-col " >
                <div className=" flex justify-start space-x-3 flex-row  ml-5 items-center  mb-1">
                        <h1 style={{textAlign: 'center' }}>Couleur supérieure</h1>
                        <h1 className={`border rounded p-3 pt-1 pb-1 border-gray-500 `} style={{textAlign: 'center' ,justifycontent:'center' , color: `${custombackgroundcolor == "#ffffff" ? "#000000" : custombackgroundcolor}`}}>{custombackgroundcolor}</h1>
                    </div>
                    <SketchPicker
                        width={250}

                        color={custombackgroundcolor}
                        onChangeComplete={(color) => setCustombackgroundcolor(color.hex)}
                    />
                    </div>
                    <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', }}>
                    <img src="/images/arrowbackground.png" alt="" style={{width: "500px", height: "100%" }} />
                </div>
                </div>
                
                <div className="fw-full grid grid-cols-2">
                <div className="flex flex-col " >
                <div className=" flex justify-start space-x-3 flex-row  ml-5 items-center  mb-1">
                            <h1 style={{textAlign: 'center' }}>Couleur du corps</h1>
                            <h1 className={`border rounded p-3 pt-1 pb-1 border-gray-500 `} style={{textAlign: 'center' ,justifycontent:'center' , color: `${custombodycolor == "#ffffff" ? "#000000" : custombodycolor}`}}>{custombodycolor}</h1>
                        </div>
                    <SketchPicker
                        width={250}

                        color={custombodycolor}
                        onChangeComplete={(color) => setCustombodycolor(color.hex)}
                        />
                        </div>
                    <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <img src="/images/arrowbody.png" alt="" style={{width: "500px", height: "100%" }} />
                </div>
                </div>
                <div className="fw-full grid grid-cols-2">
                <div className="flex flex-col " >
                <div className=" flex justify-start space-x-3 flex-row  ml-5 items-center  mb-1">
                            <h1 style={{textAlign: 'center' }}>Couleur de l'icône</h1>
                            <h1 className={`border rounded p-3 pt-1 pb-1 border-gray-500 `} style={{textAlign: 'center' ,justifycontent:'center' , color: `${iconcolor == "#ffffff" ? "#000000" : iconcolor}`}}>{iconcolor}</h1>
                        </div>
                    <SketchPicker 
                        color={iconcolor}
                        onChangeComplete={(color) => setIconcolor(color.hex)}
                        width={250}
                        />
                        </div>
                    <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        <div className="grid grid-cols-2 gap-9">
                            <img src="/images/mailcolor.png" alt="" style={{width: "75px", height: "80%" }} />
                            <img src="/images/locationcolor.png" alt="" style={{width: "75px", height: "80%" }} />
                            <img src="/images/phonecolor.png" alt="" style={{width: "75px", height: "80%" }} />
                            <img src="/images/whatsappcolor.png" alt="" style={{width: "75px", height: "80%" }} />
                        </div>
                </div>
                </div>
                <div className="fw-full grid grid-cols-2">
                <div className="flex flex-col " >
                <div className=" flex justify-start space-x-3 flex-row  ml-5 items-center  mb-1">
                            <h1 style={{textAlign: 'center' }}> titre des icônes </h1>
                            <h1 className={`border rounded p-3 pt-1 pb-1 border-gray-500 `} style={{textAlign: 'center' ,justifycontent:'center' , color: `${icontitlecolor == "#ffffff" ? "#000000" : icontitlecolor}`}}>{icontitlecolor}</h1>
                        </div>
                    <SketchPicker 
                        color={icontitlecolor}
                        onChangeComplete={(color) => setIcontitlecolor(color.hex)}
                        width={250}
                        />
                        </div>
                    <div style={{justifyContent: 'end', alignItems: 'center', display: 'flex'}}>
                    <img src="/images/fieldstitle.png" alt="" style={{width: "300px", height: "80%"  , alignSelf: 'center'}} />
                </div>
                </div>
                <div className="fw-full grid grid-cols-2">
                <div className="flex flex-col " >
                <div className=" flex justify-start space-x-3 flex-row  ml-5 items-center  mb-1">
                            <h1 style={{textAlign: 'center' }}> valeur des icônes </h1>
                            <h1 className={`border rounded p-3 pt-1 pb-1 border-gray-500 `} style={{textAlign: 'center' ,justifycontent:'center' , color: `${iconvaluecolor == "#ffffff" ? "#000000" : iconvaluecolor}`}}>{iconvaluecolor}</h1>
                        </div>
                    <SketchPicker 
                        color={iconvaluecolor}
                        onChangeComplete={(color) => setIconvaluecolor(color.hex)}
                        width={250}
                        />
                        </div>
                    <div style={{justifyContent: 'end', alignItems: 'center', display: 'flex' }}>
                    <img src="/images/fieldsvalue.png" alt="" style={{width: "300px", height: "80%"}} />
                </div>
                </div>
                <div className="fw-full grid grid-cols-2">
                <div className="flex flex-col " >
                <div className=" flex justify-start space-x-3 flex-row  ml-5 items-center  mb-1">
                    <h1 style={{textAlign: 'center' }}> information </h1>
                    <h1 className={`border rounded p-3 pt-1 pb-1 border-gray-500 `} style={{textAlign: 'center' ,justifycontent:'center' , color: `${informationcolor == "#ffffff" ? "#000000" : informationcolor}`}}>{informationcolor}</h1>
                        </div>
                    <SketchPicker 
                        color={informationcolor}
                        onChangeComplete={(color) => setInformationcolor(color.hex)}
                        width={250}
                        />
                        </div>
                    <div style={{justifyContent: 'end', alignItems: 'center', display: 'flex'}}>
                    <img src="/images/informationscolor.png" alt="" style={{width: "300px", height: "60%" }} />
                </div>
                </div>

                <div className="fw-full grid grid-cols-2 ">
                    <div className="flex flex-col pt-4" >
                        <div className="flex flex-col justify-center items-center">

                            <h1 className='pb-4'> Affichage du profile  </h1>
                            <div className="w-full flex flex-row justify-around pl-4 pr-10 ">
                                <div className="flex flex-col ">
                                    <input type="checkbox" checked={profileshape == 'rectangle'} onChange={()=>handleprofileshape('rectangle')}/>
                                    <img src="/images/rectangleimage.png" alt=""  style={{width: '75px', height: '75px'}} />
                                </div>
                                <div className="flex flex-col ">
                                    <input type="checkbox"  checked={profileshape == 'circle'} onChange={()=>handleprofileshape('circle')}/>
                                    <img src="/images/roundedimage.png" alt=""  style={{width: '75px', height: '75px'}} />
                                </div>
                            </div>

                        </div>
                        </div>

                    <div className="flex flex-col " >
                    <div className="flex flex-col pt-4" >
                        <div className="flex flex-col justify-center items-center">
                            <h1 className='pb-4'> Affichage du logo </h1>
                            <div className="w-full flex flex-row justify-around pl-4 pr-10 ">
                                <div className="flex flex-col ">
                                    <input type="checkbox" checked={logoshape == 'rectangle'} onChange={() => handlelogoshape('rectangle')}/>
                                    <img src="/images/rectangleimage.png" alt=""  style={{width: '75px', height: '75px'}} />
                                </div>
                                <div className="flex flex-col ">
                                    <input type="checkbox" checked={logoshape == 'circle'} onChange={() => handlelogoshape('circle')}/>
                                    <img src="/images/roundedimage.png" alt=""  style={{width: '75px', height: '75px'}} />
                                </div>
                            </div>

                    </div>
                    </div>
                    </div>
                </div>
            </div>
      </div>
    );
}

// titre du lien 
// libelle