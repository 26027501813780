import React, { useState, useEffect } from 'react';
import { IoCloudUploadOutline } from 'react-icons/io5';
import PropTypes from 'prop-types';

const Informations = ({ profileData, onUpdate }) => {
  const [fullName, setFullName] = useState(profileData.fullName || '');
  const [title, setTitle] = useState(profileData.title || '');
  const [departement, setDepartement] = useState(profileData.departement || '');
  const [company, setCompany] = useState(profileData.company || '');
  const [address, setAddress] = useState(profileData.address || '');
  const [aboutMe, setAboutMe] = useState(profileData.aboutMe || '');
  const [profilePhotoUrl, setProfilePhotoUrl] = useState(profileData.profilePhotoUrl || '');
  const [logoUrl, setLogoUrl] = useState(profileData.logoUrl || '');

  useEffect(() => {
    onUpdate({ fullName, title, departement, company, address, aboutMe, profilePhotoUrl, logoUrl });
  }, [fullName, title, departement, company, address, aboutMe, profilePhotoUrl, logoUrl]);

  useEffect(() => {
    setFullName(profileData.fullName || '');
    setTitle(profileData.title || '');
    setDepartement(profileData.departement || '');
    setCompany(profileData.company || '');
    setAddress(profileData.address || '');
    setAboutMe(profileData.aboutMe || '');
    setProfilePhotoUrl(profileData.profilePhotoUrl || '');
    setLogoUrl(profileData.logoUrl || '');
  }, [profileData]);

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleFileChange = (urlSetter) => (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      urlSetter(imageUrl);
    }
  };

  return (
    <div className="w-full p-6 bg-white">
      <h2 className="text-xl font-bold mb-4">Informations</h2>
      <form>
        <div className="flex flex-col md:flex-row bg-white w-full border border-gray-300 rounded-lg p-6 items-center">
          <input
            type="file"
            className="hidden"
            id="file-upload-profile"
            name="profilePhoto"
            onChange={handleFileChange(setProfilePhotoUrl)}
          />
          <label
            htmlFor="file-upload-profile"
            className="cursor-pointer flex items-center justify-center mb-0 md:mb-0"
          >
            {profilePhotoUrl ? (
              <img
                src={profilePhotoUrl}
                alt="Photo de profil"
                className="h-16 w-16 md:h-24 md:w-24"
              />
            ) : (
              <div className="h-12 w-12 md:h-24 md:w-24 text-gray-500">
                <IoCloudUploadOutline className="w-12 h-12 md:w-16 md:h-16" />
              </div>
            )}
          </label>
          <div className="text-center md:text-left md:ml-4">
            <h2 className="text-sm md:text-base font-medium">
              Cliquer pour télécharger la photo de profil
            </h2>
            <div className="text-gray-600 text-xs md:text-sm">Customer Support</div>
            <div className="text-gray-500 text-xs md:text-sm">
              SVG, PNG, JPG ou GIF (max. 800x400px)
            </div>
          </div>
        </div>

        <div className="mb-4">
          <label className="text-xs md:text-sm font-medium mb-2" htmlFor="fullName">
            Nom Complet <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className="appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full text-xs md:text-base"
            id="fullName"
            name="fullName"
            value={fullName}
            onChange={handleInputChange(setFullName)}
          />
        </div>
        <div className="mb-4">
          <label className="text-xs md:text-sm font-medium mb-2" htmlFor="title">
            Titre 
          </label>
          <textarea
            className="appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full text-xs md:text-base"
            id="title"
            name="title"
            value={title}
            onChange={handleInputChange(setTitle)}
          ></textarea>
        </div>

        <div className="mb-4">
          <label className="text-xs md:text-sm font-medium mb-2" htmlFor="departement">
            Departement 
          </label>
          <input
            type="text"
            className="appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full text-xs md:text-base"
            id="departement"
            name="departement"
            value={departement}
            onChange={handleInputChange(setDepartement)}
          />
        </div>

        <div className="mb-4">
          <label className="text-xs md:text-sm font-medium mb-2" htmlFor="company">
            Company 
          </label>
          <input
            type="text"
            className="appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full text-xs md:text-base"
            id="company"
            name="company"
            value={company}
            onChange={handleInputChange(setCompany)}
          />
        </div>

        <div className="flex flex-col md:flex-row bg-white w-full border border-gray-300 rounded-lg p-6 items-center">
          <input
            type="file"
            className="hidden"
            id="file-upload-logo"
            name="logo"
            onChange={handleFileChange(setLogoUrl)}
          />
          <label
            htmlFor="file-upload-logo"
            className="cursor-pointer flex items-center justify-center mb-0 md:mb-0"
          >
            {logoUrl ? (
              <img
                src={logoUrl}
                alt="Logo"
                className="h-16 w-16 md:h-24 md:w-24"
              />
            ) : (
              <div className="h-12 w-12 md:h-24 md:w-24 text-gray-500">
                <IoCloudUploadOutline className="w-12 h-12 md:w-16 md:h-16" />
              </div>
            )}
          </label>
          <div className="text-center md:text-left md:ml-4">
            <h2 className="text-sm md:text-base font-medium">
              Cliquer pour télécharger le logo
            </h2>
            <div className="text-gray-600 text-xs md:text-sm">Customer Support</div>
            <div className="text-gray-500 text-xs md:text-sm">
              SVG, PNG, JPG ou GIF (max. 800x400px)
            </div>
          </div>
        </div>

        <div className="mb-4">
          <label className="text-xs md:text-sm font-medium mb-2" htmlFor="address">
            Adresse
          </label>
          <input
            type="text"
            className="appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full text-xs md:text-base"
            id="address"
            name="address"
            value={address}
            onChange={handleInputChange(setAddress)}
          />
        </div>

        <div className="mb-4">
          <label className="text-xs md:text-sm font-medium mb-2" htmlFor="aboutMe">
            À propos de moi
          </label>
          <textarea
            className="appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full text-xs md:text-base"
            id="aboutMe"
            name="aboutMe"
            value={aboutMe}
            onChange={handleInputChange(setAboutMe)}
          ></textarea>
        </div>
      </form>
    </div>
  );
};

Informations.propTypes = {
  profileData: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default Informations;
