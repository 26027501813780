import React, { useState, useEffect } from 'react';
import { useParams} from 'react-router-dom';
import useProfileData from '../../../hooks/useProfileData';
import FieldsEdit from './FieldsEdit';
import ThemeEdit from './ThemeEdit';
import InformationsEdit from './InformationsEdit';
import { updateDoc, doc } from 'firebase/firestore';
import { db, storage, uploadBytes } from '../../../firebase';
import { collection } from 'firebase/firestore';
import { getDocs } from 'firebase/firestore';
import { ref } from 'firebase/storage';
import GenericProfile from '../GenericProfile/GenericProfile';
import GenericCard from '../GenericProfile/GenericCard';
import BackImg from "../../../images/BackImg.png";
import { getAuth } from 'firebase/auth';
import { getDoc } from 'firebase/firestore';
import { addDoc } from 'firebase/firestore';
import { setDoc } from 'firebase/firestore';

const ProfileEdit = ({ profileId: propProfileId, orderId: propOrderId, onCancel }) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  // Récupérer profileId et orderId des props ou des paramètres d'URL
  const { profileId: urlProfileId, orderId: urlOrderId } = useParams();
  const profileId = propProfileId || urlProfileId;
  const orderId = propOrderId || urlOrderId;
  const userId= currentUser?.uid;
  const { profileData, loading } = useProfileData(userId, profileId);
  const [activeSection, setActiveSection] = useState('informations');
  const [profileDataState, setProfileDataState] = useState(null);
  const [alert, setAlert] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
 const [custombackgroundcolor, setCustombackgroundcolor] = useState(profileData?.colorScheme?.background || '#a855f7');
 const [custombodycolor, setCustombodycolor] = useState(profileData?.colorScheme?.bodycolor || '#ffffff');
 const [fromcolor, setFromcolor] = useState(profileData?.colorScheme?.fromcolor || '#3b82f6');
 const [tocolor, setTocolor] = useState(profileData?.colorScheme?.tocolor || '#a855f7');
  useEffect(() => {
    if (profileData) {
      setProfileDataState(profileData);
    }

  }, [profileData]);

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  if (loading || !profileDataState) {
    return <div>Loading...</div>;
  }
  const handleConfirm = async () => {
    if (!profileDataState.fullName) {
      setAlert("Veuillez saisir un Nom complet.");
      return;
    }


    // Vérifier si tous les champs sélectionnés sont remplis
    for (const field of profileData.fields) {
      for (const instance of field.instances) {
        if (!instance.value) {
          setAlert(`Veuillez remplir le champ ${field.label}.`);
          return;
        }
      }
    }

    try {
      if (currentUser && profileId) {
        // Référence au document utilisateur
        const userDocRef = doc(db, 'profilesTest', currentUser.uid);
        // Référence au document spécifique dans la sous-collection profilesData
        const profileDocRef = doc(userDocRef, 'profilesData', profileId);
        // Mise à jour des données du profil
        await updateDoc(profileDocRef, {
            ...profileDataState,
            lastModified: new Date().toISOString(),
        });

        // Fonction pour convertir l'URL en blob
        const urlToBlob = async (url) => {
          try {
            const response = await fetch(url);
            if (!response.ok) {
              throw new Error(`Failed to fetch URL: ${url}`);
            }
            const blob = await response.blob();
            return blob;
          } catch (error) {
            console.error('Error converting URL to Blob:', error);
            throw error;
          }
        };

        // Téléversement de la photo de profil si elle existe
        if (profileDataState.profilePhotoUrl) {
          try {
            const profilePhotoRef = ref(storage, `images/${currentUser.uid}/${profileId}/photoProfile.png`);
            const photoBlob = await urlToBlob(profileDataState.profilePhotoUrl);
            await uploadBytes(profilePhotoRef, photoBlob);
          } catch (error) {
            console.error('Error uploading profile photo:', error);
          }
        }

        // Téléversement du logo s'il existe
        if (profileDataState.logoUrl) {
          try {
            const logoRef = ref(storage, `images/${currentUser.uid}/${profileId}/logo.png`);
            const logoBlob = await urlToBlob(profileDataState.logoUrl);
            await uploadBytes(logoRef, logoBlob);
          } catch (error) {
            console.error('Error uploading logo:', error);
          }
        }

        onCancel();
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleUpdate = (data) => {
    setProfileDataState((prevData) => ({ ...prevData, ...data }));
  };
  return (
    <div className="flex flex-col md:flex-row items-start justify-start w-full bg-white p-4 sm:p-6 md:p-20 bg-gradient-to-r from-blue-500 to-purple-500 py-12 px-4 sm:px-6 lg:px-8 border"
    style={{
      background: `linear-gradient(to right, ${profileDataState?.colorScheme?.fromcolor || '#3b82f6'}, ${profileDataState?.colorScheme?.tocolor || '#a855f7'})`,
    }}
    >
      {/* Mobile View */}
        <div className="md:hidden w-full max-w-full">
          <button onClick={handleCancel} className=''>
            <img src={BackImg} className="w-12 h-12 mb-3" alt="Icon" />
          </button>
          <h1 className="text-lg sm:text-xl font-bold mb-4">Mon Profil</h1>
          <div className='flex items-center space-x-4 sm:space-x-6 mb-4'>
            <div
              className={`cursor-pointer ${activeSection === 'informations' ? 'border-b-2 border-white text-white' : 'border-b-2 border-transparent '}`}
              onClick={() => handleSectionChange('informations')}
            >
              Informations
            </div>
            <div
              className={`cursor-pointer animate-bounce ${activeSection === 'fields' ?  'border-b-2 border-white text-white' : 'border-b-2 border-transparent '}`}
              onClick={() => handleSectionChange('fields')}
            >
              Champs ✨
            </div>
            <div

        className={`cursor-pointer pb-2 flex items-center  ${activeSection === 'theme' ? 'border-b-2 border-white text-white' : ' animate-bounce border-b-2 border-transparent'}`}

              // className={`cursor-pointer animate-bounce 'border-b-2 border-transparent '`}
              onClick={() => handleSectionChange('theme')}
            >
              theme ✨
            </div>
          </div>
          <div className="mt-4">
          {activeSection === 'informations' ? (
        <InformationsEdit profileData={profileDataState} onUpdate={handleUpdate} />
      ) : activeSection === 'fields' ? (
        <FieldsEdit 
          fields={profileDataState.fields} 
          onUpdate={handleUpdate} 
          orderId={orderId} 
          currentUserId={currentUser.uid} 
          fileUrl={fileUrl} 
          setFileUrl={setFileUrl} 
        />
      ) : (
        <ThemeEdit profileData={profileData.colorScheme} onUpdate={handleUpdate}  setCustombackgroundcolor={setCustombackgroundcolor} setCustombodycolor={setCustombodycolor} setFromcolor={setFromcolor} setTocolor={setTocolor}  custombackgroundcolor={custombackgroundcolor} custombodycolor={custombodycolor} tocolor={tocolor} fromcolor={fromcolor}/>
      )}
          </div>
          {alert && (
            <div className="mt-4 p-3 border border-red-500 text-red-500 bg-red-100 rounded">
              {alert}
            </div>
          )}
          <div className="mt-4 flex flex-col space-y-2">
            <button className="bg-white border border-gray-500 text-gray-500 px-4 py-2 rounded transition-all duration-300 ease-in-out hover:bg-white-200 hover:text-gray-400 hover:border-blue-600 transform hover:scale-105" onClick={handleCancel}>
              Annuler
            </button>
            <button className="bg-purple-600 text-white px-4 py-2 rounded transition-all duration-300 ease-in-out hover:bg-white-200 hover:text-gray-100 hover:border-blue-600 transform hover:scale-105" onClick={handleConfirm}>
              Confirmer
            </button>
          </div>
          <div className="mt-6">
            <GenericCard profileData={profileDataState} onUpdate={handleUpdate}  width = {320} height={200}/>
          </div>
          <div className="mt-6">
            <GenericProfile profileData={profileDataState}  width={370} height={200} radius={60}/>
          </div>
        </div>

      {/* Desktop View */}
<div className="hidden md:flex flex-row w-full">
  {/* Left Section: Header and Forms */}
  <div className="flex flex-col w-2/3 pr-10">
    {/* Header: Back Button and Title */}
    <div className="flex items-center mb-4"> 
      <button onClick={handleCancel} className=''>
        <img src={BackImg} className="w-12 h-12 mr-3" alt="Icon" /> 
      </button>
      <h1 className="text-xl font-bold">Mon Profil</h1>
    </div>

    {/* Tabs: Informations and Fields */}
    <div className="flex items-center space-x-6 mb-4">
      <div
        className={`cursor-pointer pb-2 ${activeSection === 'informations' ? 'border-b-2 border-white text-white' : 'border-b-2 border-transparent'}`}
        onClick={() => handleSectionChange('informations')}
      >
        Informations
      </div>
      <div
        className={`cursor-pointer pb-2 flex items-center animate-bounce ${activeSection === 'fields' ? 'border-b-2 border-white text-white' : 'border-b-2 border-transparent'}`}
        onClick={() => handleSectionChange('fields')}
      >
        Champs
        <span className="ml-2">✨</span>
      </div>
      <div
        className={`cursor-pointer pb-2 flex items-center  ${activeSection === 'theme' ? 'border-b-2 border-white text-white' : ' animate-bounce border-b-2 border-transparent'}`}
        onClick={() => handleSectionChange('theme')}
      
      >
        Theme
        <span className="ml-2">✨</span>
      </div>
    </div>

    {/* Forms Section */}
    <div className="">
      {activeSection === 'informations' ? (
        <InformationsEdit profileData={profileDataState} onUpdate={handleUpdate} />
      ) : activeSection === 'fields' ? (
        <FieldsEdit 
          fields={profileDataState.fields} 
          onUpdate={handleUpdate} 
          orderId={orderId} 
          currentUserId={currentUser.uid} 
          fileUrl={fileUrl} 
          setFileUrl={setFileUrl} 
        />
      ) : (
        <ThemeEdit profileData={profileData.colorScheme} onUpdate={handleUpdate}  setCustombackgroundcolor={setCustombackgroundcolor} setCustombodycolor={setCustombodycolor} setFromcolor={setFromcolor} setTocolor={setTocolor}  custombackgroundcolor={custombackgroundcolor} custombodycolor={custombodycolor} tocolor={tocolor} fromcolor={fromcolor}/>
      )}
    </div>
    {alert && (
      <div className="mt-4 p-3 border border-red-500 text-red-500 bg-red-100 rounded">
        {alert}
      </div>
    )}
    <div className="mt-4">
      <button className="bg-white border border-gray-500 text-gray-500 px-4 py-2 rounded transition-all duration-300 ease-in-out hover:bg-gray-100 hover:text-gray-700 transform hover:scale-105 ml-2" onClick={handleCancel}>
        Annuler
      </button>
      <button className="bg-purple-600 text-white px-4 py-2 rounded transition-all duration-300 ease-in-out hover:bg-purple-700 hover:text-white transform hover:scale-105 ml-2" onClick={handleConfirm}>
        Confirmer
      </button>
    </div>
  </div>

  {/* Right Section: Preview */}
  <div className="flex flex-col w-1/3 space-y-6">
    <GenericCard profileData={profileDataState} onUpdate={handleUpdate} />
    <div className='w-full'>
      <GenericProfile profileData={profileDataState} custombackgroundcolor={profileData?.colorScheme?.background || '#a855f7'} custombodycolor={profileData?.colorScheme?.bodycolor || '#ffffff'}/>
    </div>
  </div>
</div>

    </div>
  );
};

export default ProfileEdit;
