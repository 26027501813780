import React from 'react';

const features = [
  {
    icon: '🌐',
    description: "Accessibilité 24/7 depuis n'importe où",
    comingSoon:false,  
  }
  ,
  {
    icon: '🔄',
    description: "Mise à jour instantanée des informations",
    comingSoon:false,  
  }
  ,
  {
    icon: '🌱',
    description: "Écologique : réduction de l'empreinte carbone",
    comingSoon:false,  
  }
  ,
  {
    icon: '📱',
    description: "NFC ou QR code : ça marche sur tout les téléphones",
    comingSoon:false,  
  }
  ,
  {
    icon: '🔗👥',
    description: "Plus de liens a communiqué",
    comingSoon:false,  
  }
  ,
  {
    icon: '🛵📦',
    description: "Livraison Gratuite",
    comingSoon:false,  
  }
  ,
  {
    icon: '📊',
    description: "Statistiques détaillées",
    comingSoon:true,  
  }
  ,
 
  {
    icon: '➕',
    description: "et plus...",
    comingSoon:false 

  }
];
const planTarifaire = {
  name: 'EliteCard',
  users: 'Illimité',
  cards: 'Illimité',
  sharing: true,
  backgrounds: true,
  signatures: true,
  scans: 'Illimité',
  support: true,
  analytics: true,
  designs: true,
  videos: true,
  colors: true,
  badges: true,
  qrCode: true,
  cardLink: true,
};

const Fonctionnalite = () => {
  return (
    <div>
      <div id="avantage" className="max-w-7xl mx-auto py-2 md:py-12 px-4 sm:px-6 lg:px-8 mt-0 md:mt-16 ">
        <h2 className="text-3xl font-extrabold text-gray-900 mb-6">Ce que vous gagnez  avec <b className='text-purple-800'>EliteCard</b>  😍 ?</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-8">
          {features.map((feature, index) => (
            <div key={index} className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
              <div className="text-pink-500 text-4xl mb-4">{feature.icon}</div>
              <p className="text-gray-800 text-center">{feature.description}</p>
              {feature.comingSoon && (
                <div className="absolute top-12 right-0 bg-green-500 text-white text-xs px-1 py-0.5 rounded-full transform translate-x-4 -translate-y-2 ml-6 animate-bounce">
              Lancement Prochain
              </div>
              
                )}
            </div>
          ))}
        </div>

        <h2 id="fonct" className="text-3xl font-extrabold text-gray-900 mb-6 mt-16 md:mt-32" >Pourquoi <b className='text-purple-800'>EliteCard</b> plutôt qu'une autre 😉 ? </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border">
            <thead className="bg-gray-50">
              <tr>
                <th className="py-3 px-4 border-b"></th>
                <th className="py-3 px-4 border-b text-gray-900 font-medium">{planTarifaire.name}</th>
              </tr>
            </thead>
            <tbody>
              {[
                /* { label: 'Nombre d\'utilisateurs', key: 'users' },
               */{ label: 'Cartes de visite numériques uniques', key: 'sharing' },
                { label: 'Cartes de visite unique avec un designe distingé ', key: 'sharing' },
                { label: 'Partage de carte illimité', key: 'sharing' },
                { label: 'Code Qr en dos de la carte', key: 'sharing' },
                { label: "Impression personnalisée : votre nom et celui de l'entreprise", key: 'sharing' },
                /*  { label: 'Arrière-plans virtuels personnalisables', key: 'backgrounds' },
                */ { label: 'Styles de signature email multiples', key: 'signatures' },
                { label: 'Support client', key: 'support' },
                { label: 'Analytiques', key: 'analytics' },
                /*    { label: 'Designs de carte supplémentaires', key: 'designs' },
                *//*  { label: 'Vidéos intégrées', key: 'videos' },
                */ { label: 'Badges', key: 'badges' },
                { label: 'Code QR personnalisé', key: 'qrCode' },
                { label: 'Lien de carte personnalisé', key: 'cardLink' }
              ].map((item, rowIndex) => (
                <tr key={rowIndex} className="hover:bg-gray-100 transition-colors duration-200 ">
                  <td className="py-3 px-4 border-b font-medium">{item.label}</td>
                  <td className="py-3 px-4 border-b text-center">
                    {typeof planTarifaire[item.key] === 'boolean' ? (planTarifaire[item.key] ? '✔️' : '') : planTarifaire[item.key]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>     
      </div>
    </div>
  );
};

export default Fonctionnalite;
