// src/constants.js
export const getProfileUrl = (userId, profileId) => `${window.location.origin}/virtualBusinessCard/${userId}/${profileId}`;
// Enum pour le statut de la commande
export const OrderStatus = Object.freeze({
    PENDING_PAYMENT: "En attente de paiement",
    PROCESSING: "En cours de traitement",
    SHIPPING: "En cours de livraison",
    DELIVERED: "Livrée",
});

// Enum pour le statut de la carte
export const CardStatus = Object.freeze({
    PENDING_PAYMENT: "En attente de paiement",
    CREATING: "En cours de création",
    DELIVERED: "Livrée",
});