import { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import useAllOrdersData from './useAllOrdersData';
import useCurrentUser from './useCurrentUser';

const useAllProfilesData = () => {
  const [profilesWithOrderIds, setProfilesWithOrderIds] = useState([]);
  const orders = useAllOrdersData();
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (currentUser && orders.length > 0) {
      const userId = currentUser.uid;
      const unsubscribeList = [];
      
      try {
        // Créez une référence à la sous-collection 'profilesData' sous le document de l'utilisateur
        const profilesCollection = collection(db, 'profilesTest', userId, 'profilesData');
        orders.forEach((order) => {
          const orderId = order.id;

          
          // Filtrez les documents où le champ 'orderId' correspond à l'ID de la commande
          const profilesQuery = query(
            profilesCollection,
            where('orderId', '==', orderId)
          );

          // Récupérez les documents en temps réel qui correspondent à la requête
          const unsubscribe = onSnapshot(profilesQuery, (snapshot) => {
            const profilesFromOrder = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
              orderId // Ajout de l'ID de la commande
            }));

            setProfilesWithOrderIds(prevProfiles => [
              ...prevProfiles.filter(profile => profile.orderId !== orderId),
              ...profilesFromOrder,
            ]);
          });

          unsubscribeList.push(unsubscribe);
        });
      } catch (error) {
        console.error('Error fetching profiles:', error);
      }

      // Nettoyage : désabonnez-vous des snapshots lorsque le composant est démonté
      return () => {
        unsubscribeList.forEach(unsubscribe => unsubscribe());
      };
    }
  }, [currentUser, orders]);

  return profilesWithOrderIds;
};

export default useAllProfilesData;
